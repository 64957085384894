import React from "react"
import PageImageHeader from "../components/common/PageImageHeader"
import SectionTitle from "../components/common/SectionTitle"
import Layout from "../components/layout"
import HeaderImage from "../images/music-submission-header.jpeg"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const PageLayout = styled.div`
  max-width: 1400px;
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  img {
    width: 350px;
    margin: 2rem;
  }
`

const ArtistPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArtist {
        edges {
          node {
            slug
            bandLogo {
              fixed {
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageImageHeader imageHeader={HeaderImage} />
      <SectionTitle title="Artsits" />
      <PageLayout>
        {data.allContentfulArtist.edges.map(edge => {
          return (
            <Link to={`/artist/${edge.node.slug}`}>
              <img src={edge.node.bandLogo.fixed.src} />
            </Link>
          )
        })}
      </PageLayout>
    </Layout>
  )
}

export default ArtistPage
